import React from 'react';
import { getDateFormatted } from 'components/ui-components/DateFormat';
import Typography from 'components/ui-components-v2/Typography';

import '../styles/time.scss';

interface Props {
    milliseconds: number; // in milliseconds
    duration: number; // in milliseconds
}

const MediaControlsTime = ({ milliseconds, duration }: Props) => {
    return (
        <div className="media-controls-time">
            <Typography variant="body2" color="primary" component="span">
                {getDateFormatted(milliseconds, 'minSec')}
            </Typography>{' '}
            <Typography variant="body2" component="span">
                {getDateFormatted(duration, 'minSec')}
            </Typography>
        </div>
    );
};

export default MediaControlsTime;
