import React from 'react';
import { useWaveSurferContext } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import '../styles/loader.scss';

const AudioPlayerLoader = () => {
    const { mediaLoaded } = useWaveSurferContext();

    if (mediaLoaded) return null;

    return <CircularProgress className="audio-player-loader" />;
};

export default AudioPlayerLoader;
