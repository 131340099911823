import React, { useRef } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import { MediaPlayerProvider } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';
import { VideoPlayerControls } from 'components/ui-components-cape/VideoPlayer';
import VideoPlayerLoader from 'components/ui-components-cape/VideoPlayer/components/loader';
import VideoPlayerCommentsTimeline from './timeline';
import VideoPlayerCommentsOverlay from './overlay';

import '../styles/main.scss';

type Props = {
    /** The url to the video that should be displayed */
    url: string;
    autoPlay?: boolean;
    className?: string;
};

/**
 * VideoPlayer with comments on certain timestamps
 */
const VideoPlayerComments: React.FC<Props> = ({ url, autoPlay = false, className }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { height: containerHeight } = useComponentSize(containerRef);

    const video = useRef<HTMLVideoElement | null>(null);

    return (
        <MediaPlayerProvider mediaRef={video}>
            <div className={classNames('video-player-comments', className)} onClick={(e) => e.stopPropagation()}>
                <div ref={containerRef} className="video-player-comments__container">
                    <div className="video-player-comments__wrapper">
                        <VideoPlayerCommentsOverlay />
                        <VideoPlayerLoader />
                        <video
                            ref={video}
                            disableRemotePlayback
                            className="video-player-comments__video"
                            autoPlay={autoPlay}
                            style={{ maxHeight: containerHeight }}>
                            <source src={url} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="video-player-comments__interface">
                    <VideoPlayerControls big>
                        <VideoPlayerCommentsTimeline />
                    </VideoPlayerControls>
                </div>
            </div>
        </MediaPlayerProvider>
    );
};

export default VideoPlayerComments;
