import React, { useEffect } from 'react';
import CommentsShowMarkersOverlay from 'components/social/CommentsShowMarkersOverlay';
import { useCommentsContext } from 'components/social/context/comments.context';
import { useWaveSurferContext } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';
import AudioPlayerCommentsAddMarkerOverlay from './add-marker-overlay';

const AudioPlayerCommentsOverlay = () => {
    const { isPlaying, setIsPlaying } = useWaveSurferContext();
    const { commentsAddMarkersOverlayOpen, setCommentsAddMarkersOverlayOpen } = useCommentsContext();

    // Always start with a closed overlay.
    useEffect(() => {
        setCommentsAddMarkersOverlayOpen(false);
    }, []);

    // Remove the overlay when the audio starts playing
    useEffect(() => {
        if (isPlaying) {
            setCommentsAddMarkersOverlayOpen(false);
        }
    }, [isPlaying]);

    // We monitor commentsAddMarkersOverlayOpen to pause the audio when the overlay is opened
    useEffect(() => {
        if (commentsAddMarkersOverlayOpen) {
            setIsPlaying(false);
        }
    }, [commentsAddMarkersOverlayOpen]);

    return (
        <React.Fragment>
            <CommentsShowMarkersOverlay />
            {commentsAddMarkersOverlayOpen && <AudioPlayerCommentsAddMarkerOverlay />}
        </React.Fragment>
    );
};

export default AudioPlayerCommentsOverlay;
