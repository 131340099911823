import React from 'react';
import CommentsTimeline from 'components/social/CommentsTimeline';
import { useMediaPlayerContext } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';

const VideoPlayerCommentsTimeline = () => {
    const { milliseconds, duration, handleProgressChange, setIsPlaying } = useMediaPlayerContext();
    return <CommentsTimeline milliseconds={milliseconds} duration={duration} handleProgressChange={handleProgressChange} setIsPlaying={setIsPlaying} />;
};

export default VideoPlayerCommentsTimeline;
