import Slider from '@mui/material/Slider';
import React from 'react';

import '../styles/progress.scss';

interface Props {
    handleProgressChange: (value: number) => void;
    milliseconds: number;
    duration: number;
}

const MediaControlsProgress = ({ handleProgressChange, milliseconds, duration }: Props) => {
    return (
        <Slider
            onChange={(e: Event) => {
                handleProgressChange(parseInt((e.target as HTMLInputElement).value));
            }}
            value={milliseconds}
            min={0}
            max={duration}
            size="small"
            classes={{
                root: 'media-controls-progress',
                track: 'media-controls-progress__track',
                rail: 'media-controls-progress__rail',
                thumb: 'media-controls-progress__thumb'
            }}
        />
    );
};
export default MediaControlsProgress;
