import React, { useEffect } from 'react';
import CommentsAddMarkerOverlay from 'components/social/CommentsAddMarkerOverlay';
import CommentsShowMarkersOverlay from 'components/social/CommentsShowMarkersOverlay';
import { useCommentsContext } from 'components/social/context/comments.context';
import { useMediaPlayerContext } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';

const VideoPlayerCommentsOverlay = () => {
    const { milliseconds, isPlaying, setIsPlaying } = useMediaPlayerContext();
    const { commentsAddMarkersOverlayOpen, setCommentsAddMarkersOverlayOpen } = useCommentsContext();

    // Remove the overlay when the video starts playing
    useEffect(() => {
        if (isPlaying) {
            setCommentsAddMarkersOverlayOpen(false);
        }
    }, [isPlaying]);

    // We monitor commentsAddMarkersOverlayOpen to pause the video when the overlay is opened
    useEffect(() => {
        if (commentsAddMarkersOverlayOpen) {
            setIsPlaying(false);
        }
    }, [commentsAddMarkersOverlayOpen]);

    return (
        <React.Fragment>
            <CommentsShowMarkersOverlay />
            {commentsAddMarkersOverlayOpen && <CommentsAddMarkerOverlay milliseconds={milliseconds} />}
        </React.Fragment>
    );
};

export default VideoPlayerCommentsOverlay;
