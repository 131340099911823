import React from 'react';
import classNames from 'classnames';
import {
    MediaControlsPlay,
    MediaControlsTime,
    MediaControlsVolume,
    MediaControlsProgress,
    MediaControlsFullScreen
} from 'components/ui-components-cape/MediaControls';
import Loader from 'components/ui-components/Loader';
import { useMediaPlayerContext } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';

import '../styles/controls.scss';

type Props = {
    big?: boolean;
    children?: React.ReactNode;
    className?: string;
};

const VideoPlayerControls = ({ className, big, children }: Props) => {
    const { mediaLoaded, milliseconds, duration, volume, isPlaying, toggleFullscreen, toggleAudioMute, togglePlay, handleVolumeChange, handleProgressChange } =
        useMediaPlayerContext();

    return (
        <div className={classNames('video-player-controls', className, { 'video-player-controls--big': big })}>
            <Loader isLoading={!mediaLoaded}>
                <div className="video-player-controls__left">
                    <MediaControlsPlay isPlaying={isPlaying} togglePlay={togglePlay} big={big} />
                </div>
                <div className="video-player-controls__progress">
                    {children && <div className="video-player-controls__progress__layover">{children}</div>}
                    <MediaControlsProgress milliseconds={milliseconds} duration={duration} handleProgressChange={handleProgressChange} />
                </div>
                <div className="video-player-controls__right">
                    <MediaControlsTime milliseconds={milliseconds} duration={duration} />
                    <MediaControlsVolume volume={volume} toggleAudioMute={toggleAudioMute} handleVolumeChange={handleVolumeChange} />
                    <MediaControlsFullScreen toggleFullscreen={toggleFullscreen} />
                </div>
            </Loader>
        </div>
    );
};

export default VideoPlayerControls;
