import React from 'react';
import classNames from 'classnames';
import Loader from 'components/ui-components/Loader';
import { useWaveSurferContext } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';
import { MediaControlsPlay, MediaControlsProgress, MediaControlsTime, MediaControlsVolume } from 'components/ui-components-cape/MediaControls';

import '../styles/controls.scss';

type Props = {
    big?: boolean;
    children?: React.ReactNode;
    className?: string;
};

const AudioPlayerControls = ({ className, big, children }: Props) => {
    const { mediaLoaded, milliseconds, duration, volume, isPlaying, toggleAudioMute, togglePlay, handleVolumeChange, handleProgressChange } =
        useWaveSurferContext();
    return (
        <div className={classNames('audio-player-controls', className, { 'audio-player-controls--big': big })}>
            <Loader isLoading={!mediaLoaded}>
                <div className="audio-player-controls__left">
                    <MediaControlsPlay togglePlay={togglePlay} isPlaying={isPlaying} big={big} />
                </div>
                <div className="audio-player-controls__progress">
                    {children && <div className="audio-player-controls__progress__layover">{children}</div>}
                    <MediaControlsProgress milliseconds={milliseconds} duration={duration} handleProgressChange={handleProgressChange} />
                </div>
                <div className="audio-player-controls__right">
                    <MediaControlsTime milliseconds={milliseconds} duration={duration} />
                    <MediaControlsVolume volume={volume} toggleAudioMute={toggleAudioMute} handleVolumeChange={handleVolumeChange} />
                </div>
            </Loader>
        </div>
    );
};

export default AudioPlayerControls;
