import { useState, useRef, useEffect, RefObject } from 'react';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';

const useMediaPlayer = (
    mediaRef: RefObject<HTMLVideoElement | HTMLAudioElement>
): {
    mediaLoaded: boolean;
    milliseconds: number;
    isPlaying: boolean;
    duration: number; // In milliseconds
    volume: { current: number; beforeMute: number };
    setIsPlaying: (isPlaying: boolean) => void;
    togglePlay: () => void;
    handleProgressChange: (goToTimeInMilliSeconds: number) => void;
    toggleAudioMute: () => void;
    toggleFullscreen: () => void;
    handleVolumeChange: (_: Event, newValue: number) => void;
} => {
    const [milliseconds, setMilliseconds] = useState(0);
    const [mediaLoaded, setMediaLoaded] = useState(false);
    const [isPlaying, _setIsPlaying] = useState(false);
    const [volume, setVolume] = useState({ current: 1, beforeMute: 1 });
    // const isPlayingRef = useRef(false);
    const requestRef = useRef(0);

    // Start the animation on mount
    useEffect(() => {
        requestRef.current = requestAnimationFrame(handleTimeUpdate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    useEffect(() => {
        const media = mediaRef.current;

        setIsPlaying(media?.autoplay || false);

        const handleCanPlay = () => {
            setMediaLoaded(true);
        };

        const handelEnd = () => {
            // isPlayingRef.current = false;
            _setIsPlaying(false);
        };

        if (media) {
            media.addEventListener('canplay', handleCanPlay);
            media.addEventListener('ended', handelEnd);
            media.addEventListener('click', togglePlay);
            window.addEventListener('keydown', handleSpaceBar);
        }

        return () => {
            if (media) {
                media.removeEventListener('canplay', handleCanPlay);
                media.removeEventListener('ended', handelEnd);
                media.removeEventListener('click', togglePlay);
                window.removeEventListener('keydown', handleSpaceBar);
            }
        };
    }, [mediaRef]);

    //Play/pause when pressing space bar, not when typing. Prevent scrolling in both cases.
    const handleSpaceBar = (e: KeyboardEvent) => {
        //textarea refers to the input that might be open when the user wants to place a comment
        if (e.code === 'Space' && (e.target as HTMLTextAreaElement).type === 'textarea') {
            e.stopPropagation();
        }

        if (e.code === 'Space' && (e.target as HTMLTextAreaElement).type !== 'textarea') {
            togglePlay();
            e.preventDefault();
        }
    };

    const setIsPlaying = (isPlaying: boolean) => {
        if (isPlaying) mediaRef?.current?.play();
        else mediaRef?.current?.pause();
        _setIsPlaying(isPlaying);
    };

    const togglePlay = () => {
        if (mediaRef?.current) {
            if (mediaRef.current.paused) {
                setIsPlaying(true);
            } else {
                setIsPlaying(false);
            }
        }
    };

    const handleTimeUpdate = () => {
        if (mediaRef?.current) {
            setMilliseconds(mediaRef.current.currentTime * 1000);
        }
        requestRef.current = requestAnimationFrame(handleTimeUpdate);
    };

    const updateMediaTime = (goToTimeInSeconds: number) => {
        if (!mediaRef?.current) return;
        if (mediaRef.current.readyState >= 2) {
            mediaRef.current.currentTime = goToTimeInSeconds;
            EventEmitterHelpers.sent(EventEmitterTypes.MPcurrentFrame, goToTimeInSeconds);
        }
    };

    const duration = (() => {
        if (mediaRef.current?.duration) return mediaRef.current.duration * 1000;
        return 0;
    })();

    const handleProgressChange = (goToTimeInMilliSeconds: number) => {
        updateMediaTime(goToTimeInMilliSeconds / 1000);
        setMilliseconds(goToTimeInMilliSeconds);
    };

    const toggleAudioMute = () => {
        if (!mediaRef.current) return;

        if (volume.current) {
            setVolume({ current: 0, beforeMute: volume.current });
            mediaRef.current.volume = 0;
        } else {
            setVolume({ ...volume, current: volume.beforeMute });
            mediaRef.current.volume = volume.beforeMute;
        }
    };

    const handleVolumeChange = (_: Event, newValue: number) => {
        if (!mediaRef.current) return;
        mediaRef.current.volume = newValue;
        setVolume({ ...volume, current: newValue });
    };

    const toggleFullscreen = () => {
        if (!mediaRef.current) return;
        if (!document.fullscreenElement) {
            mediaRef.current.requestFullscreen().catch((err) => {
                alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    };

    return {
        mediaLoaded,
        milliseconds,
        isPlaying,
        duration,
        volume,
        setIsPlaying,
        togglePlay,
        handleProgressChange,
        toggleAudioMute,
        toggleFullscreen,
        handleVolumeChange
    };
};

export default useMediaPlayer;
