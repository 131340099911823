import React from 'react';
import CommentsTimeline from 'components/social/CommentsTimeline';
import { useWaveSurferContext } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';

const AudioPlayerCommentsTimeline = () => {
    const { milliseconds, duration, handleProgressChange, setIsPlaying } = useWaveSurferContext();
    return <CommentsTimeline milliseconds={milliseconds} duration={duration} handleProgressChange={handleProgressChange} setIsPlaying={setIsPlaying} />;
};

export default AudioPlayerCommentsTimeline;
