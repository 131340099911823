import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import IconButton from 'components/ui-components-v2/IconButton';

interface Props {
    toggleFullscreen: () => void;
}

const MediaControlsFullScreen = ({ toggleFullscreen }: Props) => {
    return (
        <IconButton
            size="small"
            onClick={() => {
                toggleFullscreen();
            }}
            aria-label="fullscreen">
            <Icon>fullscreen</Icon>
        </IconButton>
    );
};

export default MediaControlsFullScreen;
