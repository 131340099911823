import React, { createContext, useContext, ReactNode, RefObject } from 'react';
import useMediaPlayer from '../hooks/useMediaPlayer';

interface MediaPlayerProviderProps {
    mediaRef: RefObject<HTMLVideoElement | HTMLAudioElement>;
    children: ReactNode;
}

interface MediaPlayerContextProps {
    mediaLoaded: boolean;
    milliseconds: number;
    duration: number;
    volume: { current: number; beforeMute: number };
    isPlaying: boolean;
    togglePlay: () => void;
    handleProgressChange: (goToTimeInMilliSeconds: number) => void;
    handleVolumeChange: (_: Event, newValue: number) => void;
    toggleAudioMute: () => void;
    toggleFullscreen: () => void;
    setIsPlaying: (isPlaying: boolean) => void;
}

const MediaPlayerContext = createContext<MediaPlayerContextProps | undefined>(undefined);

MediaPlayerContext.displayName = 'MediaPlayerContext';

const MediaPlayerProvider = ({ mediaRef, children }: MediaPlayerProviderProps) => {
    const {
        mediaLoaded,
        milliseconds,
        duration,
        volume,
        handleProgressChange,
        isPlaying,
        setIsPlaying,
        togglePlay,
        toggleAudioMute,
        toggleFullscreen,
        handleVolumeChange
    } = useMediaPlayer(mediaRef);

    return (
        <MediaPlayerContext.Provider
            value={{
                mediaLoaded,
                milliseconds,
                isPlaying,
                duration,
                volume,
                setIsPlaying,
                togglePlay,
                handleVolumeChange,
                handleProgressChange,
                toggleAudioMute,
                toggleFullscreen
            }}>
            {children}
        </MediaPlayerContext.Provider>
    );
};

const useMediaPlayerContext = (): MediaPlayerContextProps => {
    const context = useContext(MediaPlayerContext);

    if (!context) {
        throw new Error('useMediaPlayerContext must be used within a MediaPlayerProvider');
    }
    return context;
};

export { MediaPlayerProvider, MediaPlayerContext, useMediaPlayerContext };
