import React, { useEffect } from 'react';
import { Comment } from 'types/comments.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import CommentsShowMarkersOverlay from 'components/social/CommentsShowMarkersOverlay';
import CommentsAddMarkerOverlay from 'components/social/CommentsAddMarkerOverlay';
import { useCommentsContext } from 'components/social/context/comments.context';

interface ComponentStoreProps {
    [entity: string]: Comment[];
}

const ImageCommentsOverlay = () => {
    const { entity, commentsAddMarkersOverlayOpen, setVisibleCommentMarkers } = useCommentsContext();

    const { comments } = useComponentStore<ComponentStoreProps>('Comments', {
        fields: {
            comments: entity
        }
    });

    useEffect(() => {
        if (comments?.length) setVisibleCommentMarkers(comments.filter((comment) => comment.x !== null && comment.y !== null).map((comment) => comment.id));
    }, [comments]);

    return (
        <React.Fragment>
            <CommentsShowMarkersOverlay />
            {commentsAddMarkersOverlayOpen && <CommentsAddMarkerOverlay />}
        </React.Fragment>
    );
};

export default ImageCommentsOverlay;
