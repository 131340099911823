import React, { createContext, useContext, ReactNode, RefObject } from 'react';
import useWaveSurfer from '../hooks/useWaveSurfer';

interface WaveSurferProviderProps {
    url: string;
    containerRef: RefObject<HTMLDivElement>;
    width: string;
    height: string;
    mode?: 'dark' | 'light'; // Are we displaying in a dark mode component?
    children: ReactNode;
}

interface WaveSurferContextProps {
    mediaLoaded: boolean;
    milliseconds: number;
    duration: number;
    volume: { current: number; beforeMute: number };
    isPlaying: boolean;
    togglePlay: () => void;
    handleProgressChange: (goToTimeInMilliSeconds: number) => void;
    handleVolumeChange: (_: Event, newValue: number) => void;
    toggleAudioMute: () => void;
    setIsPlaying: (isPlaying: boolean) => void;
}

const WaveSurferContext = createContext<WaveSurferContextProps | undefined>(undefined);

WaveSurferContext.displayName = 'WaveSurferContext';

const WaveSurferProvider = ({ url, containerRef, height, width, mode, children }: WaveSurferProviderProps) => {
    const { mediaLoaded, milliseconds, duration, volume, handleProgressChange, isPlaying, setIsPlaying, togglePlay, toggleAudioMute, handleVolumeChange } =
        useWaveSurfer(url, containerRef, width, height, mode);

    return (
        <WaveSurferContext.Provider
            value={{
                mediaLoaded,
                milliseconds,
                isPlaying,
                duration,
                volume,
                setIsPlaying,
                togglePlay,
                handleVolumeChange,
                handleProgressChange,
                toggleAudioMute
            }}>
            {children}
        </WaveSurferContext.Provider>
    );
};

const useWaveSurferContext = (): WaveSurferContextProps => {
    const context = useContext(WaveSurferContext);

    if (!context) {
        throw new Error('useWaveSurferContext must be used within a WaveSurferProvider');
    }
    return context;
};

export { WaveSurferProvider, WaveSurferContext, useWaveSurferContext };
