import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import IconButton from 'components/ui-components-v2/IconButton';

interface Props {
    isPlaying: boolean;
    big?: boolean;
    togglePlay: () => void;
}

const MediaControlsPlay = ({ isPlaying, big, togglePlay }: Props) => {
    return (
        <IconButton size={big ? 'large' : 'medium'} onClick={togglePlay} color="primary" aria-label="play">
            <Icon fontSize={big ? 'large' : 'medium'} filled>
                {isPlaying ? 'pause_circle' : 'play_circle'}
            </Icon>
        </IconButton>
    );
};

export default MediaControlsPlay;
