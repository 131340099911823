import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { MediaPlayerProvider } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';
import VideoControls from './controls';

import '../styles/main.scss';

type Props = {
    /** The url to the video that should be displayed */
    videoUrl: string;
    /** The entity or activeItemId where the VideoPlayer is used */
    withComments?: boolean;
    autoPlay?: boolean;
    big?: boolean;
    forceSize?: boolean; // For use when the size should be set by it's surounding container like in Bricks.
    classes?: {
        root?: string;
        container?: string;
        wrapper?: string;
        video?: string;
        timeline?: string;
        timelinewrapper?: string;
        interface?: string;
        controls?: string;
    };
};

const VideoPlayerComments: React.FC<Props> = ({ videoUrl, autoPlay = false, big, classes }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const video = useRef<HTMLVideoElement | null>(null);

    return (
        <MediaPlayerProvider mediaRef={video}>
            <div className={classNames('video-player', classes?.root)} onClick={(e) => e.stopPropagation()}>
                <div className={classNames('video-player__container', classes?.container)} ref={containerRef}>
                    <div className={classNames('video-player__wrapper', classes?.wrapper)}>
                        {!isVideoLoaded && <CircularProgress className="video-player__circular-progress" />}
                        <video
                            ref={video}
                            disableRemotePlayback
                            className={classNames('video-player__video', classes?.video)}
                            autoPlay={autoPlay}
                            onCanPlay={() => setIsVideoLoaded(true)}>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    </div>
                </div>
                {isVideoLoaded && (
                    <div className={classNames('video-player__interface', classes?.interface)}>
                        <VideoControls big={big} className={classNames('video-player__controls', classes?.controls)} />
                    </div>
                )}
            </div>
        </MediaPlayerProvider>
    );
};

export default VideoPlayerComments;
