import React from 'react';
import { useMediaPlayerContext } from 'components/ui-components-cape/MediaControls/context/MediaPlayerContext';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import '../styles/loader.scss';

const VideoPlayerLoader = () => {
    const { mediaLoaded } = useMediaPlayerContext();

    if (mediaLoaded) return null;

    return <CircularProgress className="video-player-loader" />;
};

export default VideoPlayerLoader;
