import React, { useRef } from 'react';
import { useComponentSize } from 'hooks/useComponentSize';
import ImageCommentsOverlay from './overlay';

import '../styles/main.scss';

interface Props {
    url: string;
}

const ImageComments = ({ url }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { height: containerHeight } = useComponentSize(containerRef);
    return (
        <div className="image-comments" ref={containerRef}>
            <div className="image-comments__wrapper">
                <ImageCommentsOverlay />
                <img src={url} className="image-comments__image" style={{ maxHeight: containerHeight }} />
            </div>
        </div>
    );
};

export default ImageComments;
