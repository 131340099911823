import React, { useRef } from 'react';
import { useComponentSize } from 'hooks/useComponentSize';
import { WaveSurferProvider } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';
import { AudioPlayerControls, AudioPlayerLoader } from 'components/ui-components-cape/AudioPlayer';
import AudioPlayerCommentsTimeline from './timeline';
import AudioPlayerCommentsOverlay from './overlay';

import '../styles/main.scss';

interface Props {
    url?: string;
    mode?: 'dark' | 'light'; // Are we displaying in a dark mode component?
}

const CONTROL_BAR_HEIGHT = 70;
const WAVE_PART_OF_PREVIEW_HEIGHT = 0.8;

// Calculate the height the wave surfer should have in it's current context. Given the height the height of the total component.
const getWaveHeight = (height?: number) => {
    return height ? Math.round((height - CONTROL_BAR_HEIGHT) * WAVE_PART_OF_PREVIEW_HEIGHT).toString() : '300';
};

const AudioPlayerComments = ({ url, mode }: Props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { width, height } = useComponentSize(componentRef);

    return (
        <WaveSurferProvider url={url || ''} containerRef={containerRef} height={getWaveHeight(height)} width={width.toString()} mode={mode}>
            <div className="audio-player-comments" ref={componentRef}>
                <div className="audio-player-comments__container">
                    <div className="audio-player-comments__wrapper">
                        <AudioPlayerCommentsOverlay />
                        <AudioPlayerLoader />
                        <div className="audio-player-comments__wave" ref={containerRef} />
                    </div>
                </div>
                <div className="audio-player-comments__interface">
                    <AudioPlayerControls big>
                        <AudioPlayerCommentsTimeline />
                    </AudioPlayerControls>
                </div>
            </div>
        </WaveSurferProvider>
    );
};

export default AudioPlayerComments;
