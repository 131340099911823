import { useState, useMemo, useEffect } from 'react';
import { Color, ColorOptions, Hex, Rgba, SimpleColor } from 'types/color.type';
import unset from 'lodash/unset';
import cloneDeep from 'helpers/cloneDeep';
import { ColorHelpers } from 'helpers/colors.helpers';

export interface UseColorPickerReturnType {
    /**
     * The active color.
     */
    activeColor: SimpleColor;
    /**
     * The hex value of the active color.
     */
    hexValue: Hex;
    /**
     * The rgb value of the active color.
     */
    rgbValue: Rgba;
    /**
     * If the color picker modal is shown.
     */
    showColorPickerModal: boolean;
    /**
     * The anchor element for the color picker modal.
     */
    anchorEl: EventTarget | null;
    /**
     * Toggle the color picker modal.
     * @param event - Mouse or touch event.
     * @returns void
     */
    handleShowColorPickerModal: (event?: MouseEvent | TouchEvent) => void;
    /**
     * Change the color type.
     * @param newType - Color type.
     * @returns void
     */
    onChangeColorType: (newType: ColorOptions) => void;
    /**
     * Change the rotation of linear gradient.
     * @param newRotation - Rotation of the linear gradient.
     * @returns void
     */
    onChangeRotation: (newRotation: Color['rotation']) => void;
    /**
     * Add, update or delete the color points.
     * @param newPoints - Color points.
     * @returns void
     */
    onChangePoints: (newPoints: Color['points']) => void;
    /**
     * Change the color.
     * @param color - Color object.
     * @param type - Color type.
     * @returns void
     */
    onChangeColor: (color: SimpleColor, type: ColorOptions) => void;
    /**
     * Save the preset color.
     * @param color - Color object.
     * @returns void
     */
    onChangePreset: (color: Color | SimpleColor) => void;
    /**
     * Change the color on change input.
     * @param name - Input name.
     * @param value - Input value.
     * @param resetAlpha - Reset the alpha value to 1.
     * @returns void
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeInput: (name: string, value: any, resetAlpha?: boolean) => void;
    /**
     * The active point in the color picker.
     */
    activePoint: number;
    /**
     * Change the active point in the color picker.
     * @param point - The active point.
     * @returns void
     */
    onChangeActivePoint: (point: number) => void;
}

const useColorPicker = (color: Color | SimpleColor, onChange: (color: Color | SimpleColor) => void, useGradients: boolean): UseColorPickerReturnType => {
    const [showColorPickerModal, setShowColorPickerModal] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
    const [activePoint, setActivePoint] = useState<number>(0);

    /**
     * Get the active color by selecting the color in the active point if it exists.
     */
    const activeColor: SimpleColor = useMemo(() => {
        if ('points' in color) {
            if (color.points[activePoint] && color.points[activePoint].color) {
                return color.points[activePoint].color as SimpleColor;
            } else {
                setActivePoint(0);
                return color.points[0].color as SimpleColor;
            }
        }

        return color as SimpleColor;
    }, [color, activePoint]);

    const [hexValue, setHexValue] = useState(activeColor.hex);
    const [rgbValue, setRgbValue] = useState(activeColor.rgb);

    /**
     * Set the hex and rgb values when the active color changes or the color picker modal is shown.
     */
    useEffect(() => {
        setHexValue(activeColor.hex);
        setRgbValue(activeColor.rgb);
    }, [activeColor, showColorPickerModal]);

    /**
     * Toggle the color picker modal.
     */
    const handleShowColorPickerModal = (event?: MouseEvent | TouchEvent) => {
        event && event.stopPropagation();

        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }

        setShowColorPickerModal((prevState) => !prevState);
    };

    /**
     * Change the color type.
     * @param newType - Color type.
     */
    const onChangeColorType = (newType: ColorOptions) => {
        const newColor = cloneDeep(color);
        newColor.type = newType;
        onChange(newColor);
    };

    /**
     * Change the rotation of linear gradient.
     * @param newRotation - Rotation of the linear gradient.
     */
    const onChangeRotation = (newRotation: Color['rotation']) => {
        const newColor = cloneDeep(color);
        newColor.rotation = newRotation;
        onChange(newColor);
    };

    /**
     * Add, update or delete the color points.
     * @param newPoints - Color points.
     */
    const onChangePoints = (newPoints: Color['points']) => {
        const newColor = cloneDeep(color);
        newColor.points = newPoints;
        onChange(newColor);
    };

    /**
     * Change the color.
     * @param newColor - Color object.
     */
    const onChangeColor = ({ rgb }: SimpleColor) => {
        const newColor = cloneDeep(color);
        const hex = ColorHelpers.convertRgbToHex(Object.values(rgb)) ?? '#000000';

        if (ColorHelpers.isSimpleColor(newColor)) {
            unset(newColor, 'points');
            newColor.rgb = rgb;
            newColor.hex = hex;
        } else {
            unset(newColor, 'rgb');
            unset(newColor, 'hex');
            newColor.points[activePoint].color.rgb = rgb;
            newColor.points[activePoint].color.hex = hex;
        }

        setHexValue(hex);
        setRgbValue(rgb);
        onChange(newColor);
    };

    /**
     * Save the preset color.
     * @param color - Color object.
     */
    const onChangePreset = (color: Color | SimpleColor) => {
        let newColor: Color | SimpleColor = cloneDeep(color);

        if (useGradients && ColorHelpers.isSimpleColor(color)) {
            newColor = ColorHelpers.convertSimpleColorToColor(cloneDeep(color));
            newColor.points[0].color.hex = color.hex;
            newColor.points[0].color.rgb = color.rgb;
        }

        // If the color picker object has points and we are not using gradients, we need to remove the points and restructure the color object.
        if (!useGradients && ColorHelpers.isColor(color)) {
            newColor = ColorHelpers.convertColorToSimpleColor(cloneDeep(color));
            newColor.hex = color.points[0].color.hex;
            newColor.rgb = color.points[0].color.rgb;
        }

        onChange(newColor);
        setShowColorPickerModal(false);

        if (ColorHelpers.isColor(newColor)) {
            setHexValue(newColor.points[activePoint].color.hex);
            setRgbValue(newColor.points[activePoint].color.rgb);
        } else {
            setHexValue(newColor.hex);
            setRgbValue(newColor.rgb);
        }
    };

    /**
     * Change the color on change input.
     * @param name - Input name.
     * @param value - Input value.
     * @param resetAlpha - Reset the alpha value to 1.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChangeInput = (name: string, value: any, resetAlpha = false) => {
        const newColor = cloneDeep(color);
        const { r, g, b } = activeColor.rgb;
        let { a = 1 } = activeColor.rgb;
        const type = name.split(' ')[0];
        let hex = activeColor.hex;
        let rgb = { r, g, b, a };

        switch (type) {
            case 'hex':
                value = value.charAt(0) !== '#' ? '#' + value : value;
                setHexValue(value);
                if (ColorHelpers.isHexColor(value)) {
                    if (resetAlpha) {
                        a = 1;
                    }
                    // If there is transparency, calculate the hex value with transparency.
                    if (a !== 1) {
                        hex = ColorHelpers.convertHexToHexAlpha(value, a) || '#000000';
                    } else {
                        hex = value;
                    }
                    const newRgb = ColorHelpers.convertHexToRgb(hex) || { r: 0, g: 0, b: 0, a: 1 };
                    rgb = {
                        r: newRgb.r,
                        g: newRgb.g,
                        b: newRgb.b,
                        a
                    };

                    if (newColor.points) {
                        newColor.points[activePoint].color.rgb = rgb;
                        newColor.points[activePoint].color.hex = hex;
                    } else {
                        newColor.rgb = rgb;
                        newColor.hex = hex;
                    }

                    onChange(newColor);
                }
                break;
            case 'rgb': {
                const channel = name.split(' ')[1];
                value = (() => {
                    if (['r', 'g', 'b'].includes(channel)) {
                        if (value > 255) return 255;
                        if (value < 0) return 0;
                    }

                    if (['a'].includes(channel)) {
                        if (value > 1) return 1;
                        if (value < 0) return 0;
                    }

                    return value;
                })();

                rgb[channel] = Number(value);
                hex = ColorHelpers.convertRgbToHex(Object.values(rgb)) || '#000000';
                setRgbValue(rgb);

                if (newColor.points) {
                    newColor.points[activePoint].color.rgb = rgb;
                    newColor.points[activePoint].color.hex = hex;
                } else {
                    newColor.rgb = rgb;
                    newColor.hex = hex;
                }

                onChange(newColor);
                break;
            }
            default:
                break;
        }
    };

    return {
        activeColor,
        hexValue,
        rgbValue,
        showColorPickerModal,
        anchorEl,
        handleShowColorPickerModal,
        onChangeColorType,
        onChangeRotation,
        onChangePoints,
        onChangeColor,
        onChangePreset,
        onChangeInput,
        activePoint,
        onChangeActivePoint: setActivePoint
    };
};

export default useColorPicker;
