import React, { useState } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';

import '../styles/volume.scss';

interface Props {
    volume: { current: number; beforeMute: number };
    toggleAudioMute: () => void;
    handleVolumeChange: (event: Event, newValue: number) => void;
}

const MediaControlsVolume = ({ volume, toggleAudioMute, handleVolumeChange }: Props) => {
    const [volumeIcon, setVolumeIcon] = useState<'volume_up' | 'volume_down' | 'volume_off'>('volume_up');

    const changeVolumeIcon = (newVolume: number) => {
        const volumeIcon: 'volume_up' | 'volume_down' | 'volume_off' = (() => {
            if (newVolume === 0) {
                return 'volume_off';
            }
            if (newVolume > 0 && newVolume < 0.6) {
                return 'volume_down';
            }
            if (newVolume >= 0.6 && newVolume <= 1) {
                return 'volume_up';
            }
            return 'volume_off';
        })();
        setVolumeIcon(volumeIcon);
    };

    const changeVolume = (event, newValue: number) => {
        changeVolumeIcon(newValue);
        handleVolumeChange(event, newValue);
    };

    const changeMute = () => {
        if (volume.current) {
            changeVolumeIcon(0);
        } else {
            changeVolumeIcon(volume.beforeMute);
        }
        toggleAudioMute();
    };

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={() => {
                    changeMute();
                }}
                aria-label="volume-control">
                <Icon>{volumeIcon}</Icon>
            </IconButton>
            <Slider
                size="small"
                onChange={(event: Event, newValue: number | number[]) => {
                    changeVolume(event, newValue as number);
                }}
                value={volume.current || 0}
                min={0}
                max={1}
                step={0.05}
                classes={{
                    root: 'media-controls-volume__slider',
                    track: 'media-controls-volume__slider__track',
                    rail: 'media-controls-volume__slider__rail',
                    thumb: 'media-controls-volume__slider__thumb'
                }}
            />
        </React.Fragment>
    );
};

export default MediaControlsVolume;
