import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { Trans } from 'react-i18next';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Typography from 'components/ui-components-v2/Typography';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import './styles/main.scss';

export interface MenuWithHelperTextItem {
    /**
     * Unique key for the menu item.
     * This is used for testing purposes.
     */
    key?: string;
    /**
     * React component to be displayed in the menu item.
     */
    component?: React.ReactNode;
    /**
     * Label for the menu item.
     */
    label?: string;
    /**
     * Icon for the menu item.
     */
    icon?: string;
    /**
     * End icon for the menu item.
     */
    endAdornment?: React.ReactNode;
    /**
     * Tooltip for the menu item.
     */
    tooltip?: string;
    /**
     * Helper text for the menu item.
     */
    helperText?: string;
    /**
     * Hide the menu item.
     */
    hide?: boolean;
    /**
     * Disable the menu item.
     */
    disabled?: boolean;
    /**
     * If the menu item is selected.
     */
    selected?: boolean;
    /**
     * Function to be called when the menu item is clicked.
     */
    onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    /**
     * Class name for the menu item.
     */
    className?: string;
}

export interface MenuWithHelperTextProps {
    /**
     * If the menu can be opened.
     * @default true
     */
    canBeOpened?: boolean;
    /**
     * If the menu is open.
     * This way the menu can be controlled from the parent component.
     */
    open?: boolean;
    /**
     * Menu trigger element.
     * The onClick event will be overridden.
     */
    trigger?: React.ReactElement;
    /**
     * Position of the dropdown.
     * If not provided, the dropdown will be displayed below the trigger element.
     */
    dropdownPosition?: { top: number | null; left: number | null };
    /**
     * Menu items to be displayed.
     */
    items: MenuWithHelperTextItem[];
    /**
     * Function to be called when the menu is opened.
     * @param event - Event from trigger click.
     */
    onOpen?: (event: React.MouseEvent<HTMLDivElement>) => void;
    /**
     * Function to be called when the menu is closed.
     */
    onClose?: () => void;
    /**
     * If the menu should stay open.
     */
    stayOpen?: boolean;
    /**
     * Minimum width of the menu.
     */
    minWidth?: number;
    /**
     * Data-cy prefix for testing.
     */
    dataCyPrefix?: string;
}

/**
 * The MenuWithHelperText component is a wrapper around the Menu component. It displays a list of clickable items with helper text beneath it.
 *
 * - [MenuWithHelperText documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/621051905/MenuWithHelperText)
 */
const MenuWithHelperText = ({
    canBeOpened = true,
    open,
    trigger,
    dropdownPosition,
    items,
    onOpen,
    onClose,
    stayOpen,
    minWidth,
    dataCyPrefix
}: MenuWithHelperTextProps) => {
    /**
     * State to keep track of the hovered value.
     */
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    /**
     * State to keep track of the menu anchor element.
     */
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const [highestHelpersText, setHighestHelperText] = useState<number>(0);
    const helperTextHolderRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLSpanElement>(null);

    /**
     * Reference to the widest div in the menu.
     */
    const [widestMenuItem, setWidestMenuItem] = useState<{ current: HTMLElement | null; width: number }>({ current: null, width: 0 });

    const menuOpen = open ?? Boolean(menuAnchorEl);

    const anchorReference = dropdownPosition?.top && dropdownPosition.left ? 'anchorPosition' : undefined;
    const anchorPos =
        dropdownPosition && dropdownPosition.top !== null && dropdownPosition.left !== null
            ? { top: dropdownPosition.top ?? 0, left: dropdownPosition.left ?? 0 }
            : undefined;

    /**
     * Update the highestHelperText state with the highest helper text height.
     */
    const updateHeighestHelperText = () => {
        if (!helperTextHolderRef.current) return;

        const childNodes = Array.from(helperTextHolderRef.current.childNodes).filter((node): node is HTMLElement => node instanceof HTMLElement);
        let newHighestHelpersText = 0;
        childNodes.forEach((node) => {
            if (node.offsetHeight > newHighestHelpersText) {
                newHighestHelpersText = node.offsetHeight;
            }
        });

        setHighestHelperText(newHighestHelpersText);
    };

    /**
     * Observe the parent element for changes in the children.
     * This is used to update the highestHelperText state.
     * It is in a use effect with an observer because when this component mounts it does not yet render the menu.
     */
    useEffect(() => {
        if (!parentRef.current) return;

        const observer = new MutationObserver(() => {
            updateHeighestHelperText();
        });

        observer.observe(parentRef.current, { childList: true, subtree: true });

        return () => observer.disconnect(); // Cleanup on unmount
    }, []);

    /**
     * Update the highestHelperText state when the menu items change.
     */
    useEffect(() => {
        updateHeighestHelperText();
    }, [widestMenuItem]);

    /**
     * Menu items to be displayed.
     * Filter out items that are hidden.
     */
    const menuItems = useMemo(() => {
        if (!items) return [];
        return items.filter((item) => !item.hide);
    }, [items]);

    /**
     * Helper texts for the menu items.
     */
    const helperTexts = useMemo(() => menuItems.map((menuItem) => menuItem.helperText).filter((helperText) => !!helperText), [menuItems]) as string[];

    /**
     * Open the input options menu.
     * @param event - Event from event listener.
     */
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        if (menuItems.length === 0) return;
        setMenuAnchorEl(event.currentTarget);
    };

    /**
     * Close the input options menu.
     */
    const handleClose = () => {
        if (stayOpen) return;
        setMenuAnchorEl(null);
        onClose?.();
    };

    return (
        <span ref={parentRef}>
            {trigger &&
                React.cloneElement(trigger, {
                    onClick: (event) => {
                        if (!canBeOpened) return;
                        event.stopPropagation();
                        event.preventDefault();
                        onOpen?.(event);
                        handleOpen?.(event);
                    }
                })}
            <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={() => {
                    setMenuAnchorEl(null);
                    onClose?.();
                }}
                anchorReference={anchorReference}
                anchorPosition={anchorPos}
                component="div"
                slotProps={{
                    paper: {
                        className: classNames('ui-v2-cape-menu-with-helper-text__menu', {
                            'ui-v2-cape-menu-with-helper-text__menu--helper-text': helperTexts.length > 0 && menuOpen
                        })
                    }
                }}>
                <li className="ui-v2-cape-menu-with-helper-text__menu__items">
                    {menuItems.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                                title={
                                    item.tooltip ? (
                                        <span>
                                            <Trans>{item.tooltip}</Trans>
                                        </span>
                                    ) : undefined
                                }
                                disableInteractive
                                placement="left">
                                <span style={{ width: '100%' }}>
                                    {/* Ensure Tooltip can capture hover events */}
                                    <MenuItem
                                        data-cy={`${dataCyPrefix}MoreMenu${item.key}-button`}
                                        component="div"
                                        className={classNames(item.className)}
                                        disabled={item.disabled}
                                        selected={item.selected}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            item.onClick?.(event);
                                            handleClose();
                                        }}
                                        onMouseEnter={() => {
                                            if (!item.helperText) return setHoveredIndex(null);
                                            setHoveredIndex(index);
                                        }}
                                        ref={(el) => {
                                            if (el && el.offsetWidth > (widestMenuItem.current?.offsetWidth || 0)) {
                                                if (minWidth && el.offsetWidth < minWidth) {
                                                    return setWidestMenuItem({ current: el, width: minWidth });
                                                }
                                                setWidestMenuItem({ current: el, width: el.offsetWidth });
                                            }
                                        }}>
                                        {item.component && item.component}
                                        {item.icon && !item.component && (
                                            <Icon fontSize="small" className="ui-v2-cape-menu-with-helper-text__menu__icon">
                                                {item.icon}
                                            </Icon>
                                        )}
                                        {!item.component && (
                                            <Typography
                                                variant="caption"
                                                className={classNames('ui-v2-cape-menu-with-helper-text__menu__label', {
                                                    'ui-v2-cape-menu-with-helper-text__menu__label--extra-space': item.endAdornment
                                                })}>
                                                {item.label}
                                            </Typography>
                                        )}
                                        {/* Place endAdornment outside MenuItem to ensure Tooltip works */}
                                        {item.endAdornment && !item.component && (
                                            <span className="ui-v2-cape-menu-with-helper-text__menu__end-adornment">{item.endAdornment}</span>
                                        )}
                                    </MenuItem>
                                </span>
                            </Tooltip>
                        </div>
                    ))}
                </li>
                {helperTexts.length > 0 && menuOpen && (
                    <li>
                        <div
                            className="ui-v2-cape-menu-with-helper-text__helper-text-holder"
                            ref={helperTextHolderRef}
                            style={{ width: widestMenuItem.width + 16, height: highestHelpersText }}>
                            {helperTexts.map((helperText, index) => (
                                <div
                                    key={index}
                                    className={classNames('ui-v2-cape-menu-with-helper-text__helper-text', {
                                        'ui-v2-cape-menu-with-helper-text__helper-text--active': hoveredIndex === index
                                    })}
                                    style={{ width: widestMenuItem.width + 16 }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(helperText)
                                    }}
                                />
                            ))}
                        </div>
                    </li>
                )}
            </Menu>
        </span>
    );
};

export default MenuWithHelperText;
