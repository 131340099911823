import React, { useEffect, useRef, useState } from 'react';
import CommentsAddMarkersOverlayInput from 'components/social/CommentsAddMarkerOverlay/components/input';
import { useCommentsContext } from 'components/social/context/comments.context';
import { useWaveSurferContext } from 'components/ui-components-cape/MediaControls/context/WafeSurferContext';

import '../styles/add-marker-overlay.scss';

const AudioPlayerCommentsAddMarkerOverlay = () => {
    const { milliseconds, duration } = useWaveSurferContext();
    const { setCommentsAddMarkersOverlayOpen } = useCommentsContext();
    const [coordinatesInPercentage, setCoordinatesInPercentage] = useState<{ x: string; y: string }>({ x: '', y: '' });
    const overlay = useRef<HTMLDivElement>(null);

    useEffect(() => {
        saveCoordinatesToState();
    }, [milliseconds]);

    const saveCoordinatesToState = () => {
        const rect = overlay.current?.getBoundingClientRect();
        if (rect) {
            // Calculate percentages
            const xPercentage = (milliseconds / duration) * 100;
            setCoordinatesInPercentage({ x: xPercentage.toFixed(2) + '%', y: '50%' });
        }
    };

    return (
        <div ref={overlay} className="audio-player-comments-add-marker-overlay">
            <CommentsAddMarkersOverlayInput
                overlay={overlay}
                coordinatesInPercentage={coordinatesInPercentage}
                milliseconds={milliseconds}
                open={coordinatesInPercentage.x.length > 0}
                onClose={() => setCommentsAddMarkersOverlayOpen(false)}
            />
        </div>
    );
};

export default AudioPlayerCommentsAddMarkerOverlay;
