import { RefObject, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import WaveSurfer from 'wavesurfer.js';

const useWaveSurfer = (
    url: string,
    containerRef: RefObject<HTMLDivElement>,
    width: string,
    height: string,
    mode?: 'dark' | 'light' // Are we displaying in a dark mode component?
): {
    mediaLoaded: boolean;
    milliseconds: number;
    isPlaying: boolean;
    duration: number;
    volume: { current: number; beforeMute: number };
    setIsPlaying: (isPlaying: boolean) => void;
    togglePlay: () => void;
    toggleAudioMute: () => void;
    handleVolumeChange: (_: Event, newValue: number) => void;
    handleProgressChange: (goToTimeInMilliSeconds: number) => void;
} => {
    const theme = useTheme();
    const [milliseconds, setMilliseconds] = useState(0);
    const [mediaLoaded, setMediaLoaded] = useState(false);
    const [volume, setVolume] = useState({ current: 1, beforeMute: 1 });
    const [duration, setDuration] = useState(0);
    const requestRef = useRef(0);
    const isPlayingRef = useRef(false);
    const waveSurferRef = useRef<any | null>(null);

    // Start the animation on mount
    useEffect(() => {
        requestRef.current = requestAnimationFrame(handleTimeUpdate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleSpaceClick);

        return () => {
            window.removeEventListener('keydown', handleSpaceClick);
            waveSurferRef.current.destroy();
        };
    }, []);

    useEffect(() => {
        if (!waveSurferRef.current) return;
        waveSurferRef.current.setHeight(height);
        waveSurferRef.current.drawBuffer();
    }, [height]);

    useEffect(() => {
        if (!waveSurferRef.current) return;
        waveSurferRef.current.drawBuffer();
    }, [width]);

    useEffect(() => {
        waveSurferRef.current = WaveSurfer.create({
            container: containerRef.current,
            waveColor: mode === 'dark' ? 'white' : theme.palette.text.primary,
            progressColor: theme.palette.primary.main,
            cursorColor: theme.palette.error.main,
            cursorWidth: 2,
            height: height,
            barWidth: 2,
            barRadius: 4,
            barGap: 2,
            pixelRatio: 3,
            normalize: true,
            hideScrollbar: true
        });

        if (waveSurferRef.current) waveSurferRef.current.load(url);

        waveSurferRef.current.on('ready', handleReady);
        waveSurferRef.current.on('finish', handleAudioEnded);

        return () => {
            if (waveSurferRef.current) waveSurferRef.current.destroy();
            waveSurferRef.current = null;
        };
    }, [url]);

    const handleTimeUpdate = () => {
        if (waveSurferRef?.current) {
            if (waveSurferRef.current.isPlaying) setMilliseconds(waveSurferRef.current.getCurrentTime() * 1000);
        }
        requestRef.current = requestAnimationFrame(handleTimeUpdate);
    };

    //Play/pause when pressing space bar, not when typing. Prevent scrolling in both cases.
    const handleSpaceClick = (e: KeyboardEvent) => {
        //textarea refers to the input that might be open when the user wants to place a comment
        if (e.code === 'Space' && (e.target as HTMLTextAreaElement).type === 'textarea') {
            e.stopPropagation();
        }

        if (e.code === 'Space' && (e.target as HTMLTextAreaElement).type !== 'textarea') {
            togglePlay();
            e.preventDefault();
        }
    };

    const setIsPlaying = (isPlaying: boolean) => {
        if (isPlaying) waveSurferRef.current.play();
        else waveSurferRef.current.pause();
        isPlayingRef.current = isPlaying;
    };

    const togglePlay = () => {
        waveSurferRef.current.playPause();
        isPlayingRef.current = waveSurferRef.current.isPlaying;
    };

    const handleReady = () => {
        setMediaLoaded(true);
        setDuration(waveSurferRef.current.getDuration() * 1000);
    };

    const handleAudioEnded = () => {
        waveSurferRef.current.seekTo(0);
        waveSurferRef.current.stop();
        isPlayingRef.current = false;
        setMilliseconds(0);
    };

    const toggleAudioMute = () => {
        if (!waveSurferRef.current) return;

        if (volume.current) {
            setVolume({ current: 0, beforeMute: volume.current });
            waveSurferRef.current.setVolume(0);
        } else {
            setVolume({ ...volume, current: volume.beforeMute });
            waveSurferRef.current.setVolume(volume.beforeMute);
        }
    };

    const handleVolumeChange = (_: Event, newValue: number) => {
        if (!waveSurferRef.current) return;
        waveSurferRef.current.setVolume(newValue);
        setVolume({ ...volume, current: newValue });
    };

    const handleProgressChange = (goToTimeInMilliSeconds: number) => {
        if (!waveSurferRef.current) return;
        waveSurferRef.current.setCurrentTime(goToTimeInMilliSeconds / 1000);
        setMilliseconds(goToTimeInMilliSeconds);
    };

    return {
        mediaLoaded,
        milliseconds,
        isPlaying: isPlayingRef.current,
        duration,
        volume,
        setIsPlaying,
        togglePlay,
        toggleAudioMute,
        handleVolumeChange,
        handleProgressChange
    };
};

export default useWaveSurfer;
